import React from 'react';
import {
    TextField,
    ImageField,
    Labeled
} from 'react-admin';
import PasswordField from '../fields/PasswordField';
import CustomTableShow from './CustomTableShow';
import ConditionalField from '../fields/ConditionalField';

const ignoredFields = [
    'disabled',
    'brand',
    'city',
    'hotel'
];

class UserShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (ignoredFields.includes(fieldName)) {
            return true
        } else if (fieldName === 'password') {
            fieldList.push(<PasswordField source={fieldName} />)
            return true
        } else if (fieldName === 'name') {
            fieldList.push(<TextField label="Account name" source={fieldName}/>)
            return true
        } else if (fieldName === "logo") {
            fieldList.push(
                <ConditionalField condition={(record) => record.role === 'exhibitor'}>
                    <div>
                        <Labeled source='brand'>
                            <TextField source='brand'/>
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label="Logo">
                            <ImageField source="logo.url" field="logo.url" />
                        </Labeled>
                    </div>
                </ConditionalField>
            )
            return true
        } else if (fieldName === "country") {
            fieldList.push(
                <ConditionalField condition={(record) => record.role === 'hyatt' || record.role === 'admin'}>
                    <div>
                        <Labeled source='country'>
                            <TextField source='country'/>
                        </Labeled>
                    </div>
                    <div>
                        <Labeled source='city'>
                            <TextField source='city'/>
                        </Labeled>
                    </div>
                    <div>
                        <Labeled source='hotel'>
                            <TextField source='hotel'/>
                        </Labeled>
                    </div>
                </ConditionalField>
            )
            return true
        }
        return false
    }
}

export default UserShow;
