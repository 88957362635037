import React, { Component } from 'react';
import {
  ReferenceInput,
  SelectInput
} from 'react-admin';

class SubCategoriesForCategoryField extends Component {

  state = {
    category: null
  }

  updateCategory(categoryId) {
    this.setState({ category: categoryId });
  }

  render() {
    let filter = {}

    if (this.state.category) {
      console.log(`filter by selected category : categoryId = ${this.state.category}`)
      filter = {category: this.state.category}
    } else if (this.props.record.category && this.props.record.category.id) {
      console.log(`filter by category of record: categoryId = ${this.props.record.category.id}`)
      filter = {category: this.props.record.category.id}
    }

    return (
      <ReferenceInput source="subcategory" label="Sub Category" reference="subcategories" allowEmpty filter={filter} onChange={this.props.onChange}>
        <SelectInput optionText="name" />
      </ReferenceInput>)
  }
};

SubCategoriesForCategoryField.defaultProps = {
  addLabel: false,
};
  export default SubCategoriesForCategoryField;
