import React, { Component } from 'react';
import {
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';

class TagsForCategoryField extends Component {

  state = {
    category: null,
    subcategory: null,
  }

  updateCategory(categoryId) {
    this.setState({ category: categoryId });
    this.setState({ subcategory: null });
  }

  updateSubCategory(subcategoryId) {
    this.setState({ subcategory: subcategoryId });
  }

  render() {
    let filter = {}

    if (this.state.subcategory) {
      console.log(`filter by selected subcategory : subcategoryId = ${this.state.subcategory}`)
      filter = {subcategory: this.state.subcategory}
    } else if (this.state.category) {
      console.log(`filter by selected category : categoryId = ${this.state.category}`)
      filter = {category: this.state.category}
    } else if (this.props.record.subcategory && this.props.record.subcategory.id) {
      console.log(`filter by subcategory of record: subcategoryId = ${this.props.record.subcategory.id}`)
      filter = {subcategory: this.props.record.category.id}
    } else if (this.props.record.category && this.props.record.category.id) {
      console.log(`filter by category of record: categoryId = ${this.props.record.category.id}`)
      filter = {category: this.props.record.category.id}
    }
    return (
      <ReferenceArrayInput source="tags" label="Tags" reference="tags" allowEmpty filter={filter} sort={{field: 'immutable', order: 'DESC'}}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    );
  }
};

TagsForCategoryField.defaultProps = {
  addLabel: false,
};
  export default TagsForCategoryField;
