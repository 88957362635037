// eslint-disable-next-line import/no-anonymous-default-export
export default {

    getPlural: (sigularWord) => {
        const lastChar = sigularWord.substr(sigularWord.length - 1);
        sigularWord = sigularWord.replace('_', '-');
        if (lastChar === 'y') {
            return `${sigularWord.substring(0, sigularWord.length - 1)}ies`;
        }
        return `${sigularWord}s`;
    },

    displayFieldForPlural: (plural) => {
        let optionText = 'name';

        if (plural === 'videos' || plural === 'series') {
            optionText = 'title';
        }
        return optionText;
    },

    downloadFile: (file, fileName) => {
        const url = window.URL.createObjectURL(file);
        let link = document.createElement("a");
        link.href = url
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }
};