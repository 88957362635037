import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    TextInput,
    DateInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import dateUtils from '../utils/dateUtils';
import SubCategoriesForCategoryField from '../fields/SubCategoriesForCategoryField';
import TagsForCategoryField from '../fields/TagsForCategoryField';
import utils from '../utils/utils';
import { COUNTRY_TITLE, EXHIBITOR_TITLE } from '../utils/constants';

class ExhibitorEdit extends CustomTableEdit {

    constructor(props) {
        super(props)
        this.subCategories = React.createRef()
        this.tags = React.createRef()
    }

    state = {  
        ...super.state,
        hideDelete: true
    }

    ShowTitle = () => utils.getPlural(EXHIBITOR_TITLE);

    changeCategory = (event)=>{
        this.subCategories.current.updateCategory(event.target.value)
        this.tags.current.updateCategory(event.target.value)
    }

    changeSubCategory = (event)=>{
        this.tags.current.updateSubCategory(event.target.value)
    }

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo' ||
            // fieldName === 'exhibitor' ||
            fieldName === 'contact' ||
            fieldName === 'contact_text' ||
            fieldName === 'wechat_qr_code' ||
            fieldName === 'catalogs' ||
            fieldName === 'carousel' ||
            fieldName === 'video_thumbmnail_links' ||
            fieldName === 'thumbnails' ||
            fieldName === 'images_videos' ||
            fieldName === 'videos' ||
            fieldName === 'published' ||
            fieldName === 'lastEmailSent' ||
            fieldName === 'products' ||
            fieldName === 'product_ids' ||
            fieldName === 'background' ||
            fieldName === 'history' ||
            fieldName === 'history_media' ||
            fieldName === 'countriesAvailable' ||
            fieldName === 'wechat' ||
            fieldName === 'lifestyle' ||
            fieldName === 'lifestyles' || 
            fieldName === 'facebook_link' ||
            fieldName === 'instagram_link' ||
            fieldName === 'linkedin_link' ||
            fieldName === 'tiktok_link' ||
            fieldName === 'kakao_link' ||
            fieldName === 'youtube_link' ||
            fieldName === 'red_link' ||
            fieldName === 'weibo_link'
            ) {
            return true;
        } else if (
            fieldName === 'name' ||
            fieldName === 'website_url' ||
            fieldName === 'category' ||
            fieldName === 'tags' ||
            fieldName === 'hyatt_tags' ||
            fieldName === 'description' ||
            fieldName === 'countries'
        ) {
            if (this.props.permissions === 'super-admin') {
                if (fieldName === 'category') {
                    fieldList.push(<ReferenceInput source="category.id" label="Category" reference="categories" allowEmpty onChange={this.changeCategory}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>);
                    return true;
                } else if (fieldName === 'tags') {
                    fieldList.push(<TagsForCategoryField source={fieldName} label="Announcement Tags" ref={this.tags}/>)
                    // fieldList.push(
                    //     <ReferenceArrayInput source="tags" label="Tags" reference="tags" allowEmpty>
                    //         <AutocompleteArrayInput optionText="name" />
                    //     </ReferenceArrayInput>)
                    return true;
                } else if (fieldName === 'hyatt_tags') {
                    fieldList.push(<ReferenceArrayInput source="hyatt_tags" label="Announcement Tags" reference="hyatt-tags" allowEmpty>
                            <SelectArrayInput optionText="name" />
                        </ReferenceArrayInput>);
                    return true;
                } else if (fieldName === 'countries') {
                    fieldList.push(
                        <ReferenceArrayInput source="countries" label={COUNTRY_TITLE} reference="countries" allowEmpty>
                            <AutocompleteArrayInput optionText="name" />
                        </ReferenceArrayInput>
                    )
                    return true
                } else if (fieldName === 'description') {
                    fieldList.push(<TextInput multiline source={fieldName} label="Description"/>)
                    return true
                }
                return false
            } else {
                return true
            }
        } else if (fieldName === 'subscription_end') {
            fieldList.push(<DateInput source={fieldName} label="Subscription ends" parse={dateUtils.dateParser} />)
            return true
        } else if (fieldName === 'subscription_type') {
            // Deprecated
            // fieldList.push(<SelectInput source={fieldName} label="Subscription type" choices={this.transformInChoices(fields[fieldName].enum)} />)
            return true
        } else if (fieldName === 'subcategory') {
            fieldList.push(<SubCategoriesForCategoryField source="subcategory.id" label="Sub Category" ref={this.subCategories} allowEmpty onChange={this.changeSubCategory}/>)
            return true
        } else if (fieldName === 'exhibitor') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Account name" source="exhibitor.id" reference={plural} allowEmpty>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>)
            return true
        }
        return false
    }

}

export default ExhibitorEdit;
