import React from 'react';
import {
    TextInput,
    FileInput,
    FileField,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class HomepageEdit extends CustomTableEdit {

    state = {  
        ...super.state,
        hideDelete: true
    }
    
    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'announcement_banner') {          
            fieldList.push(<FileInput source={fieldName} label="Announcement banner" multiple={false} accept="image/*">
                <FileField source="announcement_banner" title="title" />
                {/* <TextField source="name" label="Name"/> */}
                {/* <ImageOrVideoField source="url" field="url" title="title" /> */}

            </FileInput>)

            return true

        } else if (fieldName === 'announcement_exhibitor') {
            fieldList.push(<TextInput source={fieldName} label="Announcement (for Exhibitors)" multiline/>)
            return true

        } else if (fieldName === 'announcement_hyatt') {
            fieldList.push(<TextInput source={fieldName} label="Announcement (for Hyatt)" multiline/>)
            return true

        } else if (fieldName === 'announcement_tag') {
            fieldList.push(
                <ReferenceInput source="announcement_tag" label="Tag" reference="hyatt-tags" allowEmpty>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            )
            return true

        } else if (fieldName === 'announcement_title') {
            fieldList.push(<TextInput source={fieldName} label="Announcement title" multiline/>)
            return true

        }
        return false
    }

}

export default HomepageEdit;
