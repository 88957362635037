import React from 'react';
import {
    ImageField
} from 'react-admin'
import CustomTableShow from './CustomTableShow';

class HomepageShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === "announcement_banner") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ImageField source="announcement_banner.url" field="announcement_banner.url" label="Banner" />)
            return true
        }
        return false
    }
}

export default HomepageShow;
