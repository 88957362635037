import React from 'react';
import {
    TextInput,
    TextField,
    ImageInput,
    ImageField,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class CategoryEdit extends CustomTableEdit {

    state = {  
        ...super.state,
        hideDelete: true
    }

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'name') {
            fieldList.push(<TextField source={fieldName}/>)
            return true
        } else if(fieldName === 'slug') {
            return true
        } else if (fieldName === 'description') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput source={fieldName} label={fieldLabel} multiline/>)
            return true
        } else if (fieldName === 'small_icon') {
            fieldList.push(<ImageInput source={fieldName} label="Small icon (accept .svg)" accept="image/svg+xml">
                <ImageField source="url" title="title" />
            </ImageInput>)
            return true
        }
        return false
    }

}

export default CategoryEdit;
