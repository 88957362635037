import React from 'react';
import {
    ImageInput,
    ImageField,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';


class ExhibitorCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo') {
            fieldList.push(<ImageInput source={fieldName} label="Logo" multiple={false} accept="image/*">
                <ImageField source="logo" title="title" />
            </ImageInput>)
            return true
        }
        return false
    }

}

export default ExhibitorCreate;
