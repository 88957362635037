import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class ProductTagList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput label="Category" source="category" reference="categories" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="SubCategory" source="subcategory" reference="subcategories" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'immutable') {
            return true
        }
        return false
    }

}

export default ProductTagList;
