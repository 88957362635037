
import decodeJwt from 'jwt-decode';

const authProvider = {
    // login: ({ username, password }) => {
    //     const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login`, {
    //         method: 'POST',
    //         body: JSON.stringify({ account_name: username, password }),
    //         headers: new Headers({ 'Content-Type': 'application/json' }),
    //     });
    //     return fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error("Username or password incorrect");
    //             }
    //             return response.json();
    //         })
    //         .then(({ token }) => {
    //             const decodedToken = decodeJwt(token);
    //             localStorage.setItem('token', token);
    //             localStorage.setItem('permissions', decodedToken.permissions);
    //         });
    // },
    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login2`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error("Username or password incorrect");
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.permissions);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: error => {

        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();

    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {

        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;
