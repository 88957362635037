import React from 'react';

const frontUrl = process.env.REACT_APP_FRONT_URL;

const FrontUrlField = ({ record, field, target }) => {

  if (record.category) {
    const url = `${frontUrl}/exhibit/${record.id}`
    console.log(url)

    return <div className="RaLabeled-value-90">
          <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href={url} target={target}>{url}</a>
        </div>
    }
    return <div/>
    
  };

FrontUrlField.defaultProps = {
    addLabel: true,
};

  export default FrontUrlField;