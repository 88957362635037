import React from 'react';
import {
    TextField,
    Filter,
    SelectInput,
    ImageField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class UserList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>

            <SelectInput source='role' label="Role" choices={[
			{ id: 'super-admin', name: 'super-admin' },
			{ id: 'admin', name: 'admin' },
			{ id: 'exhibitor', name: 'exhibitor' },
			{ id: 'hyatt', name: 'hyatt' }]} />

        </Filter>
    );
    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'password' || fieldName === 'disabled' || fieldName === 'favourites') {
            return true;
        } else if (fieldName === 'name') {
            fieldList.push(<TextField label="Account name" source={fieldName}/>)
            return true;
        } else if (fieldName === 'logo') {
            fieldList.push(<ImageField source="logo.url" field="logo.url" label="Logo" />)
        }
        return false;
    }
}

export default UserList;
