import React from 'react';
import {
    ImageField,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class HotelBrandList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo') {
            fieldList.push(<ImageField source="logo.url" field="logo.url" label="Logo" />)
        }

        return false
    }
}

export default HotelBrandList;
