import React from 'react';

const ImageOrVideoField = ({ record, field }) => {

  let str = record[field];
  if (str) {
    if (str.endsWith('.jpg') || str.endsWith('.jpeg') || str.endsWith('.png')) {
      return (
        <div>
          <img src={record[field]} alt={record['title']} width={300} />
        </div>
      )
    } else if (str.endsWith('.mp4') || str.endsWith('.avi')) {
      return (
      <div>
        <video controls width={300}>
          <source src={record[field]} type="video/webm"/>
          <source src={record[field]} type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      )
    }
  }
return <a href={record[field]} >{record['title']}</a>

};

export default ImageOrVideoField;