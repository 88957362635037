import React from 'react';
import slugify from 'slugify'
import {
    ImageInput,
    ImageField,
    TextInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

class SubCategoryCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'hash') {
            return true
        } else if (fieldName === 'slug') {
            fieldList.push(<TextInput
                label="Slug"
                source={fieldName}
                onInput = {(e) =>{
                    e.target.value = slugify(e.target.value).toLowerCase()
                }}/>)
            return true
        } else if (fieldName === 'icon') {
            fieldList.push(<ImageInput source={fieldName} label="Icon" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>)
            return true
        } else if (fieldName === 'background') {
            fieldList.push(<ImageInput source={fieldName} label="Background" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>)
            return true
        } else if (fieldName === 'small_icon') {
            fieldList.push(<ImageInput source={fieldName} label="Small icon (accept .svg)" accept="image/svg+xml">
                <ImageField source="src" title="title" />
            </ImageInput>)
            return true
        }
        return false
    }

}

export default SubCategoryCreate;
