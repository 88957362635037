import React from 'react';
import {
    Filter,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    EmailField,
    BooleanField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class HotelBrandList extends CustomTableList {


    myFilter = (props) => (
        <Filter {...props}>
            {/* <TextInput label="Search" source="name" alwaysOn /> */}
            <ReferenceInput label="Hotel brand" source="hotel_brand" reference="hotel-brands" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="Country" source="country" reference="countries" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'email') {
            return true;
        } else if (fieldName === 'hotel_brand') {
            fieldList.push(<ReferenceField source={fieldName} reference={"hotel-brands"} link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true
        } else if (fieldName === 'recap_email') {
            fieldList.push(
                <EmailField source={fieldName} label='Email' />
            )
            return true
        } else if (fieldName === 'isUpComing') {
            fieldList.push(
                <BooleanField source={fieldName} label='Pipeline' />
            )
            return true
        } else if (fieldName === 'district') {
            fieldList.push(
                <TextField source={fieldName} label='Additional information' />
            )
            return true
        }
        return false
    }
}

export default HotelBrandList;
