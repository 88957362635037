import React from 'react';
import { BooleanField, EmailField, ReferenceField, TextField } from 'react-admin'
import CustomTableShow from './CustomTableShow';

class HotelContactShow extends CustomTableShow {
    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'hotel_brand') {
            fieldList.push(
                <ReferenceField source={fieldName} reference={"hotel-brands"} link="show">
                    <TextField source="name" />
                </ReferenceField>
            )
            return true
        } else if (fieldName === 'recap_email') {
            fieldList.push(
                <EmailField source={fieldName} label='Email' />
            )
            return true
        } else if (fieldName === 'isUpComing') {
            fieldList.push(
                <BooleanField source={fieldName} label='Pipeline' />
            )
            return true
        } else if (fieldName === 'district') {
            fieldList.push(
                <TextField source={fieldName} label='Additional information' />
            )
            return true
        }
        return false
    }
}

export default HotelContactShow;
