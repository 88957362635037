import React from 'react';
import { Admin, Resource } from 'react-admin';

import HomepageIcon from '@material-ui/icons/Home';
import ExhibitorIcon from '@material-ui/icons/MenuBook';
import ProductIcon from '@material-ui/icons/Cake';
import CategoryIcon from '@material-ui/icons/Folder';
import SubCategoryIcon from '@material-ui/icons/FileCopy';
import TagIcon from '@material-ui/icons/LocalOffer';
import ProductTagIcon from '@material-ui/icons/FreeBreakfast';
import HyattTagIcon from '@material-ui/icons/Stars';
import UserIcon from '@material-ui/icons/Person';
import CountryIcon from '@material-ui/icons/Flag';
import HotelBrandIcon from '@material-ui/icons/House';
import HotelContactIcon from '@material-ui/icons/ContactPhone';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import MyLoginPage from './pages/MyLoginPage';

import HomepageList from './lists/HomepageList';
import HomepageEdit from './edits/HomepageEdit';

import ExhibitorCreate from './creates/ExhibitorCreate';
import ExhibitorEdit from './edits/ExhibitorEdit'
import ExhibitorList from './lists/ExhibitorList';
import ExhibitorShow from './shows/ExhibitorShow'

import ProductList from './lists/ProductList';
import ProductEdit from './edits/ProductEdit';

import CategoryEdit from './edits/CategoryEdit';

import SubCategoryCreate from './creates/SubCategoryCreate';
import SubCategoryEdit from './edits/SubCategoryEdit';
import SubCategoryList from './lists/SubCategoryList';

import HotelBrandCreate from './creates/HotelBrandCreate';
import HotelBrandEdit from './edits/HotelBrandEdit';
import HotelBrandList from './lists/HotelBrandList';
import HotelBrandShow from './shows/HotelBrandShow';

import HotelContactCreate from './creates/HotelContactCreate';
import HotelContactEdit from './edits/HotelContactEdit';
import HotelContactList from './lists/HotelContactList';
import HotelContactShow from './shows/HotelContactShow';

import TagList from './lists/TagList';
import TagShow from './shows/TagShow';
import TagCreate from './creates/TagCreate';
import TagEdit from './edits/TagEdit';

import ProductTagList from './lists/ProductTagList';
import ProductTagShow from './shows/ProductTagShow';
import ProductTagCreate from './creates/ProductTagCreate';
import ProductTagEdit from './edits/ProductTagEdit';

import UserList from './lists/UserList';
import UserShow from './shows/UserShow';
import UserEdit from './edits/UserEdit';
import UserCreate from './creates/UserCreate';

import './App.scss';
import CustomTableCreate from './creates/CustomTableCreate';
import CustomTableEdit from './edits/CustomTableEdit';
import CustomTableShow from './shows/CustomTableShow';
import CustomTableList from './lists/CustomTableList';

import HyattTheme from './HyattTheme';
import CategoryList from './lists/CategoryList';
import HomepageShow from './shows/HomepageShow';
import utils from './utils/utils';
import { COUNTRY_TITLE, EXHIBITOR_TITLE, PRODUCT_TITLE } from './utils/constants';
import CountryList from './lists/CountryList';
import CountryShow from './shows/CountryShow';
import CountryEdit from './edits/CountryEdit';

class App extends React.Component {

  render() {

  const ressources = []

  ressources.push()

  return <Admin
    // dashboard={Dashboard}
    loginPage={MyLoginPage}
    theme={HyattTheme}
    authProvider={authProvider}
    dataProvider={dataProvider}>
    {permissions => [

  <Resource
    options={{ label: 'Homepage' }}
    name={'homepages'}
    // create={CustomTableCreate}
    list={HomepageList}
    edit={permissions === 'super-admin' ? HomepageEdit : null}
    show={HomepageShow}
    icon={HomepageIcon} />,

  <Resource
    options={{ label: utils.getPlural(EXHIBITOR_TITLE) }}
    name={'exhibitors'}
    create={ExhibitorCreate}
    list={ExhibitorList}
    edit={ExhibitorEdit}
    show={ExhibitorShow}
    icon={ExhibitorIcon} />,

    <Resource
    options={{ label: utils.getPlural(PRODUCT_TITLE) }}
    name={'products'}
    list={ProductList}
    // show={ProductShow}
    edit={permissions === 'super-admin' ? ProductEdit : null}
    icon={ProductIcon} />,

    <Resource
    name={'categories'}
    // create={CustomTableCreate}
    list={CategoryList}
    edit={permissions === 'super-admin' ? CategoryEdit : null}
    // show={CustomTableShow}
    icon={CategoryIcon} />,

    <Resource
    name={'subcategories'}
    create={permissions === 'admin' || permissions === 'super-admin' ? SubCategoryCreate : null}
    list={SubCategoryList}
    edit={permissions === 'admin' || permissions === 'super-admin' ? SubCategoryEdit : null}
    // show={CustomTableShow}
    icon={SubCategoryIcon} />,

    <Resource
    options={{ label: 'Tags (brand)' }}
    name={'tags'}
    create={permissions === 'super-admin' ? TagCreate : null}
    list={TagList}
    edit={permissions === 'super-admin' ? TagEdit : null}
    show={TagShow}
    icon={TagIcon} />,

    <Resource
    options={{ label: 'Tags (product)' }}
    name={'product-tags'}
    create={permissions === 'super-admin' ? ProductTagCreate : null}
    list={ProductTagList}
    edit={permissions === 'super-admin' ? ProductTagEdit : null}
    show={ProductTagShow}
    icon={ProductTagIcon} />,

    <Resource
    options={{ label: 'Announcement' }}
    name={'hyatt-tags'}
    create={permissions === 'super-admin' ? CustomTableCreate : null}
    list={CustomTableList}
    edit={permissions === 'super-admin' ? CustomTableEdit : null}
    show={CustomTableShow}
    icon={HyattTagIcon} />,

    <Resource
    options={{ label: utils.getPlural(COUNTRY_TITLE) }}
    name={'countries'}
    create={permissions === 'super-admin' ? CustomTableCreate : null}
    list={CountryList}
    edit={permissions === 'super-admin' ? CountryEdit : null}
    show={CountryShow}
    icon={CountryIcon} />,

    <Resource
    options={{ label: 'Hotel brands' }}
    name={'hotel-brands'}
    create={permissions === 'admin' || permissions === 'super-admin' ? HotelBrandCreate : null}
    list={HotelBrandList}
    edit={permissions === 'admin' || permissions === 'super-admin' ? HotelBrandEdit : null}
    show={HotelBrandShow}
    icon={HotelBrandIcon} />,

    <Resource
    options={{ label: 'Hotel contacts' }}
    name={'hotel-contacts'}
    create={permissions === 'admin' || permissions === 'super-admin' ? HotelContactCreate : null}
    list={HotelContactList}
    edit={permissions === 'admin' || permissions === 'super-admin' ? HotelContactEdit : null}
    show={HotelContactShow}
    icon={HotelContactIcon} />,

  // permissions === 'admin' ? <Resource
  //   name={'reports'}
  //   // create={ReportCreate}
  //   list={ReportList}
  //   // edit={ReportEdit}
  //   show={ReportShow}
  //   icon={ReportIcon} /> : null,

  permissions === 'admin' || permissions === 'super-admin' ? <Resource
    name={'users'}
    create={permissions === 'admin' || permissions === 'super-admin' ? UserCreate : null}
    list={UserList}
    edit={permissions === 'admin' || permissions === 'super-admin' ? UserEdit : null}
    show={UserShow}
    icon={UserIcon} /> : null,

    // <Resource
    // options={{ label: 'Notifications' }}
    // name={'notifications'}
    // list={CustomTableList}
    // show={CustomTableShow}
    // icon={NotificationIcon} />,

    ]}
  </Admin>
  }
  
}

require('dotenv').config()

export default App;
