import React from 'react'
import BarChartContainer from './BarChartContainer'
import moment from 'moment'

const Statistics = ({ record }) => {
    
    let totalCatalogDownload = 0
	let totalContactDownload = 0
	let totalVideoStartPlaying = 0
	let formattedStats = []
	const now = new Date()
    for (const stat in record.stats) {
        totalCatalogDownload+= record.stats[stat].catalogDownload
		totalContactDownload+= record.stats[stat].contactDownload
		totalVideoStartPlaying+= record.stats[stat].videoStartPlaying
		const date = moment(record.stats[stat].period)
		formattedStats.unshift({
			date: `${date.format('MMM').toString()}${date.toDate().getFullYear() !== now.getFullYear() ? ` ${date.toDate().getFullYear()}` : ''}`,
			fullDate: date,
			pv: record.stats[stat].pageView
		})
	}
	formattedStats.sort(function (a, b) {
		return a.fullDate.toDate() - b.fullDate.toDate();
	})

	// record.statsByCountriesPercent = {
	// 	Belgium: 90,
	// 	China: 8,
	// 	Russia: 1,
	// 	Other: 1
	// }


	let statsByCountriesPercentArray = []
	
	for (const i in record.statsByCountriesPercent) {
		const countryView = { country: i, views: record.statsByCountriesPercent[i]}
		if (countryView.country === null || countryView.country === 'null') {
			countryView.country = "Other"
		}
		countryView.views = countryView.views.toFixed(2)
		statsByCountriesPercentArray.push(countryView)
	}
	
	statsByCountriesPercentArray.sort(function (a, b) {
		return b.views - a.views;
	});

	console.log(statsByCountriesPercentArray)


	return (

		<div>
			<div className="">
				Followers: {record.followersAmount}
			</div>
			<div className="statistics centered-section">

				<br/>
				<div className="statistics__bar-chart">
				{formattedStats.length >= 1 &&
					<BarChartContainer data={formattedStats}/>
				}
				{formattedStats.length < 1 &&
					<div className="statistics-stat">
						<div className="statistics-stat__data">
							No graph data
						</div>
						<div className="statistics-stat__label">
							available for this user
						</div>
					</div>
				}
				</div>
				<div className="statistics-stat__wrap">
					<div className="statistics-stat">
						<div className="statistics-stat__data">
							{totalCatalogDownload}
						</div>
						<div className="statistics-stat__label">
							Catalogue downloads
						</div>
					</div>
					<div className="statistics-stat">
						<div className="statistics-stat__data">
							{totalContactDownload}
						</div>
						<div className="statistics-stat__label">
							Contact information downloads
						</div>
					</div>
					<div className="statistics-stat">
						<div className="statistics-stat__data">
							{totalVideoStartPlaying}
						</div>
						<div className="statistics-stat__label">
							Number of video views
						</div>
					</div>
				</div>
			</div>

			{/* <div class="statistics-row">
				{latestViews.map(key => 

				<div class="statistics-row-item">
					<a class="brandCard subscription" href="/hotels">
						<div class="brandCard__name">
							<span>Someone from {key.}</span>
						</div>
					</a>
				</div>
				}
				
			</div> */}

			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a className="brandCard stats" >
				<div class="brandCard__name">Latest views</div>
				<ul>
					{record.latestViews && record.latestViews.map(view => 
						<li class="brandCard__row">
						<div class="brandCard__key">{view.city} {view.brand} ({view.hotel})</div>
					</li>)}
				</ul>
			</a>

			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a className="brandCard stats" >
				<div class="brandCard__name">Hotel views</div>
				<ul>
					{record.statsByHotels && Object.keys(record.statsByHotels).sort(function (a, b) { return record.statsByHotels[b] - record.statsByHotels[a]; }).map(key => 
						<li class="brandCard__row">
						<div class="brandCard__key">{key === 'null null' ? 'Other' : key}</div>
						<div class="brandCard__percentage">{record.statsByHotels[key]}</div>

					</li>)}
				</ul>
			</a>

			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a className="brandCard stats" >
				<div class="brandCard__name">Geographic repartition</div>
				<ul>
					{statsByCountriesPercentArray.map(key => 
						<li class="brandCard__row">
						<div class="brandCard__key">{key.country}</div>
						<div class="brandCard__percentage">{key.views}%</div>
					</li>)}
				</ul>
			</a>
		</div>
	)
}

Statistics.defaultProps = {
    addLabel: true,
};


export default Statistics