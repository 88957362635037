import React from 'react';
import {
    TextInput,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

const ignoredFields = [
     'disabled',
     'favourites',
     'lastLoginAt',
     'brand',
     'city',
     'hotel'
];

class UserCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (ignoredFields.includes(fieldName)) {
            return true
        } else if (fieldName === 'name') {
            fieldList.push(<TextInput source={fieldName} label="Account name"/>)
            return true
        } else if (fieldName === 'role') {
            const role = localStorage.getItem('permissions')
            if (role === 'admin') {
                fieldList.push(<SelectInput label="Role" source={fieldName} choices={this.transformInChoicesWithout(fields[fieldName].enum, ['super-admin'])} />)
            } else {
                fieldList.push(<SelectInput label="Role" source={fieldName} choices={this.transformInChoices(fields[fieldName].enum)} />)
            }
            return true;
        } else if (fieldName === 'logo') {
            fieldList.push(
                <FormDataConsumer>
                    {({ formData }) => ( formData.role === 'exhibitor' &&
                        <>
                            <div><TextInput source='brand' style={{ width: '256px' }} /></div>
                            <ImageInput source={fieldName} label="Logo" multiple={false} accept="image/*">
                                <ImageField source="logo" title="title" />
                            </ImageInput>
                        </>
                    )}
                </FormDataConsumer>
            )
            return true
        } else if (fieldName === 'country') {
            fieldList.push(
                <FormDataConsumer>
                    {({ formData }) => ( (formData.role === 'hyatt' || formData.role === 'admin') &&
                        <>
                            <div><TextInput source='country' style={{ width: '256px' }} /></div>
                            <div><TextInput source='city' style={{ width: '256px' }} /></div>
                            <div><TextInput source='hotel' style={{ width: '256px' }} /></div>
                        </>
                    )}
                </FormDataConsumer>,
            )
            return true
        }
        return false
    }

}

export default UserCreate;
