import React from 'react';
import {
    Login,
} from 'react-admin';

import LoginForm from '../forms/LoginForm.js'

const MyLoginPage = () => {
    return <Login
        // backgroundImage="https://atelierbraam.com/wp-content/uploads/2019/05/Illustration-homepage-textes-min.jpg.webp"
        // backgroundImage="https://atelierbraam.com/wp-content/uploads/2019/05/Illustration-homepage-textes-min.jpg.webp"
        // backgroundImage="radial-gradient(circle at 50% 14em, #da2a1d 0%, #00023b 60%, #00023b 100%)"
        // backgroundImage={login_background}
        children={<LoginForm />}
    />
};

  export default MyLoginPage;
