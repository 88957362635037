import utils from '../utils/utils';
import { COUNTRY_TITLE } from '../utils/constants';
import CustomTableEdit from './CustomTableEdit';

class CountryEdit extends CustomTableEdit {
    ShowTitle = () => utils.getPlural(COUNTRY_TITLE);

    customHandled() {
        return false;
    }

}

export default CountryEdit;
