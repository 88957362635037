import React from 'react';
import {
    ImageField
} from 'react-admin'
import CustomTableShow from './CustomTableShow';

class HotelBrandShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === "logo") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ImageField source="logo.url" field="logo.url" label="Logo" />)
            return true
        }
        return false
    }
}

export default HotelBrandShow;
