import React from 'react';
import {
    FileInput,
    FileField
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class HotelBrandEdit extends CustomTableEdit {

    state = {  
        ...super.state,
        hideDelete: true
    }
    
    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo') {          
            fieldList.push(<FileInput source={fieldName} label="Logo" multiple={false} accept="image/*">
                <FileField source="logo" title="title" />
            </FileInput>)

            return true

        }
          
        return false
    }

}

export default HotelBrandEdit;
