import React from 'react';

import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import utils from '../utils/utils';
import { EXHIBITOR_TITLE, PRODUCT_TITLE } from '../utils/constants';
import { ReferenceField, TextField } from 'react-admin';

class ProductList extends CustomTableList {
    ShowTitle = () => utils.getPlural(PRODUCT_TITLE);

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'product') {
            fieldList.push(<ImageFromAPIField label="Product" source="product" field="uri" folder="products" height="150"/>);
            return true;
        } else if (fieldName === 'exhibitor') {
            fieldList.push(
                <ReferenceField source={fieldName} label={EXHIBITOR_TITLE} reference='exhibitors' link="show">
                    <TextField source="name" />
                </ReferenceField>
            );

            return true;
        } else if (fieldName === 'countries') {
            return true;
        }
        return false
    }
}

export default ProductList;
