import React from 'react';
import {
    Show,
    SimpleShowLayout,
    FileField,
    ImageField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    UrlField,
    useTranslate,
    ReferenceField,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ArrayField,
    NumberField,
    downloadCSV,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import CustomTableList from './CustomTableList';
import ElipsizeTextField from '../fields/ElipsizeTextField'
import FrontUrlField from '../fields/FrontUrlField'
import utils from '../utils/utils';
import ImageOrVideoField from '../fields/ImageOrVideoField'
import ActiveField from '../fields/ActiveField'
import jsonExport from 'jsonexport/dist';
import dayjs from 'dayjs';
import dataProvider from '../dataProvider';
import { EXHIBITOR_TITLE } from '../utils/constants';


const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

class ExhibitorList extends CustomTableList {
    ShowTitle = () => utils.getPlural(EXHIBITOR_TITLE);

    myFilter = (props) => (
        <Filter {...props}>
            {/* <TextInput label="Search" source="name" alwaysOn /> */}
            <ReferenceInput label="Category" source="category" reference="categories" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <QuickFilter source="isPinned" label="Pinned on top" defaultValue={true} />

            <ReferenceInput label="Account name" source="exhibitor" reference="users" allowEmpty >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'catalogs' || fieldName === 'carousel' || fieldName === 'images_videos' ||
            fieldName === 'facebook_link' || fieldName === 'instagram_link' || fieldName === 'linkedin_link' || fieldName === 'tiktok_link' ||
            fieldName === 'kakao_link' || fieldName === 'youtube_link' || fieldName === 'red_link' || fieldName === 'weibo_link' ||
            fieldName === 'website_url' || fieldName === 'tags' || fieldName === 'countries' || fieldName === 'countriesAvailable' ||
            fieldName === 'video_thumbmnail_links' || fieldName === 'thumbnails' || fieldName === 'products' ||
            fieldName === 'published' || fieldName === 'videos' || fieldName === 'hyatt_tags' ||
            fieldName === 'lifestyle' || fieldName === 'lifestyles' || fieldName === 'contact_text' || fieldName === 'history' ||
            fieldName === 'wechat' || fieldName === 'product_ids' || fieldName === 'subscription_type') {
            return true
        } else if (fieldName === 'logo') {
            fieldList.push(<ImageField className="thumbNailView" label="Logo" source="logo.url" title="logo.title" />)
            return true;
        } else if (fieldName === 'name') {
            fieldList.push(<TextField label="Brand name" source={fieldName}/>)
            return true
        } else if (fieldName === 'exhibitor') {
            const plural = utils.getPlural(fields[fieldName].ref)

            fieldList.push(<ReferenceField label="Account name" source="exhibitor.id" reference={plural} link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'subscription_end') {
            fieldList.push(<ActiveField label="Subscription ends" source={fieldName} />)
            return true;
        } else if (fieldName === 'description') {
            // fieldList.push(<ElipsizeTextField source={fieldName} field={fieldName}/>)
            return true
        } else if (fieldName === 'category') {
            fieldList.push(<ReferenceField label="Category" source="category.id" reference="categories" link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'subcategory') {
            fieldList.push(<ReferenceField label="Sub category" source="subcategory" reference="subcategories" link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'lastEmailSent') {
            // fieldList.push(<DateField source={fieldName} showTime />)
            fieldList.push(<NumberField source={"allViewCount"}/>)
            fieldList.push(<NumberField source={"monthlyViewCount"}/>)
            // fieldList.push(<FunctionField render={(record) => <ExportButton key="csv-export" exporter={() => {}} onClick={(event) => customExport(record, event)}/>}/>);
            return true;
        } else if (fieldName === 'notes') {
            fieldList.push(<ElipsizeTextField source="notes" field="notes" maxLength={20}/>)
            return true;
        }
        return false
    }

    canExport = async (exhibitors) => {
        const exhibitorsForExport = [];
        for (const exhibitor of exhibitors) {
            const {
                name,
                category,
                subcategory,
                subscription_type,
                subscription_end,
                // type,
                // notes,
                // pinned,
                // disabled,
                viewCountGroupByMonth,
                viewCountCurrentYear,
                catalogDownload,
                contactDownload,
                videoStartPlaying,
                followersAmount,
            } = exhibitor; 

            const accountName = exhibitor?.exhibitor.name ? exhibitor?.exhibitor.name : '';

            let subcategoryName = '';
            if (subcategory && subcategory !== null) {
                subcategoryName = await dataProvider.getOne('subcategories', {id: subcategory}).then(
                    ({data: {name}}) => `${name}`
                ).catch((error) => {
                    console.log(error);
                });
            }
            
            let viewCountPerMonth = {};
            if (viewCountGroupByMonth) {
                for (const data of viewCountGroupByMonth) {
                    viewCountPerMonth[data._id.month] = data.count;
                }
            }
            for (let i = 1; i <= 12; i++) {
                if (!viewCountPerMonth[i]) {
                    viewCountPerMonth[i] = 0;
                }
            }
            const pageViewHeader = `Page views(${dayjs().year()})`;
            exhibitorsForExport.push({
                'Exhibitor': name,
                'Account': accountName,
                'Category': category ? category.name : '',
                'Sub-category': subcategoryName ? subcategoryName : '',
                'Subscription type': subscription_type,
                'Subscription ends': dayjs(subscription_end).format('DD/MM/YYYY'),
                'Follows': followersAmount,
                // 'Type': type,
                // 'Notes': notes,
                // 'Pinned': pinned ? 'Yes' : 'No',
                // 'Disabled': disabled ? 'Yes' : 'No',
                'January': viewCountPerMonth[1],
                'February': viewCountPerMonth[2],
                'March': viewCountPerMonth[3],
                'Q1 Total': viewCountPerMonth[1] + viewCountPerMonth[2] + viewCountPerMonth[3],
                'April': viewCountPerMonth[4],
                'May': viewCountPerMonth[5],
                'June': viewCountPerMonth[6],
                'Q2 Total': viewCountPerMonth[4] + viewCountPerMonth[5] + viewCountPerMonth[6],
                'Jul': viewCountPerMonth[7],
                'Aug': viewCountPerMonth[8],
                'Sep': viewCountPerMonth[9],
                'Q3 Total': viewCountPerMonth[7] + viewCountPerMonth[8] + viewCountPerMonth[9],
                'Oct': viewCountPerMonth[10],
                'Nov': viewCountPerMonth[11],
                'Dec': viewCountPerMonth[12],
                'Q4 Total': viewCountPerMonth[10] + viewCountPerMonth[11] + viewCountPerMonth[12],
                [pageViewHeader]: viewCountCurrentYear,
                'Catalogue downloads': catalogDownload,
                'Contact downloads': contactDownload,
                'Number of video plays': videoStartPlaying,
            });
        }
    
        jsonExport(
            exhibitorsForExport,
            {
                headers: [
                    'Exhibitor',
                    'Account',
                    'Category',
                    'Sub-category',
                    'Subscription type',
                    'Subscription ends',
                    'Follows',
                    // 'Type',
                    // 'Notes',
                    // 'Pinned',
                    // 'Disabled',
                    'January',
                    'February',
                    'March',
                    'Q1 Total',
                    'April',
                    'May',
                    'June',
                    'Q2 Total',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Q3 Total',
                    'Oct',
                    'Nov',
                    'Dec',
                    'Q4 Total',
                    `Page views(${dayjs().year()})`,
                    'Catalogue downloads',
                    'Contact downloads',
                    'Number of video plays',
                ] // exhibitors fields in the export
            },
            (err, csv) => {
                downloadCSV(csv, 'exhibitors'); // download as 'posts.csv` file
            }
        );
    };

    PostShow(props){
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout>
                <ElipsizeTextField source="description" field="description" maxLength={100}/>

                <TextField label="About" source="history"/>

                <ReferenceArrayField source="tags" reference="tags">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>

                <ReferenceArrayField source="hyatt-tags" reference="hyatt-tags" label="Announcement Tags">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>

                {/* <ReferenceArrayField source="countries" reference="countries">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField> */}
                <FileField label="Contact" source="contact.url" title="contact.title" target="_blank"/>
                <FileField source="catalogs" src="url" title="title" target="_blank"/>
                <ArrayField source="carousel">
                    <SingleFieldList linkType={false}>
                        <ImageOrVideoField source="url" field="url" title="carousel" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="images_videos">
                    <SingleFieldList linkType={false}>
                        <ImageOrVideoField source="url" field="url" title="carousel" />
                    </SingleFieldList>
                </ArrayField>
                <ImageField label="Wechat QR code" source="wechat_qr_code.url" title="wechat_qr_code.title" />
                <UrlField source="facebook_link" target="_blank"/>
                <UrlField source="instagram_link" target="_blank"/>
                <UrlField source="linkedin_link" target="_blank"/>
                <UrlField source="website_url" target="_blank"/>
                <UrlField source="website_url" target="_blank"/>
                <FrontUrlField label="HDE link" source="hdr_link" target="_blank"/>

            </SimpleShowLayout>
        </Show>
    }

}

export default ExhibitorList;
