import { stringify } from 'query-string';
import { fetchUtils, HttpError } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const httpClientFile = (url, params) => {
    return fetch(params.data.file.src)
    .then(r => {
        return r.blob()
    })
    .then(blob => {
        const formData  = new FormData();
        formData.append("data", JSON.stringify(params.data));
        formData.append("file", blob)

        const token = localStorage.getItem('token');

        return fetch(url,  {
            method: 'POST',
            body: formData,
            headers: new Headers(
                {
                    // "Content-Type": 'multipart/form-data',
                    'Authorization': `JWT ${token}`
                }
            )
        })
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};

export const httpClientXXXFiles = async (url, params, method, fieldName) => {
    const formData  = new FormData();
    formData.append("data", JSON.stringify(params.data));

    if (!params.data[fieldName] || !params.data[fieldName].rawFile) {

    } else {
        const data = await fetch(params.data[fieldName][fieldName])
        const blob = await data.blob()
        formData.append(fieldName, blob)
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: method,
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};

export const httpClientAnnouncementBannerFiles = async (url, params, method) => {
    return httpClientXXXFiles(url, params, method, 'announcement_banner')
};

export const httpClientHotelLogoFiles = async (url, params, method) => {
    return httpClientXXXFiles(url, params, method, 'logo')
};

export const httpClientPartnerLogoFiles = async (url, params, method) => {
    return httpClientXXXFiles(url, params, method, 'logo')
};

export const httpClientSubCategoryFile = async (url, params) => {
    const formData  = new FormData();
    formData.append("data", JSON.stringify(params.data));

    if (params.data.icon.rawFile == null) {

    } else {
        const data = await fetch(params.data.icon.src)
        const blob = await data.blob()
        formData.append(`icon`, blob)
    }

    if (params.data.background.rawFile == null) {

    } else {
        const data = await fetch(params.data.background.src)
        const blob = await data.blob()
        formData.append(`background`, blob)
    }

    if (params.data.small_icon.rawFile == null) {

    } else {
        const data = await fetch(params.data.small_icon.src)
        const blob = await data.blob()
        formData.append(`small_icon`, blob)
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: 'POST',
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};


export const httpClientSubCategoryUpdateFile = async (url, params) => {
    const formData  = new FormData();

    console.log("httpClientSubCategoryUpdateFile")

    formData.append("data", JSON.stringify(params.data));

    if (params.data.icon.rawFile == null) {

    } else {
        const data = await fetch(params.data.icon.url)
        const blob = await data.blob()
        formData.append(`icon`, blob)
    }

    if (params.data.background.rawFile == null) {

    } else {
        const data = await fetch(params.data.background.url)
        const blob = await data.blob()
        formData.append(`background`, blob)
    }

    if (params.data.small_icon) {
        if (params.data.small_icon.rawFile == null) {

        } else {
            const data = await fetch(params.data.small_icon.url)
            const blob = await data.blob()
            formData.append(`small_icon`, blob)
        }
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: 'PUT',
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};

export const httpClientCategoryUpdateFile = async (url, params) => {
    const formData  = new FormData();

    console.log("httpClientCategoryUpdateFile")

    formData.append("data", JSON.stringify(params.data));

    if (params.data.small_icon) {
        if (params.data.small_icon.rawFile == null) {

        } else {
            const data = await fetch(params.data.small_icon.url)
            const blob = await data.blob()
            formData.append(`small_icon`, blob)
        }
    }

    const token = localStorage.getItem('token');

    return fetch(url,  {
        method: 'PUT',
        body: formData,
        headers: new Headers(
            {
                // "Content-Type": 'multipart/form-data',
                'Authorization': `JWT ${token}`
            }
        )
    })
    .then(response => {
        return response.text().then(text => {
            return {
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }
         })
    })
    .then(({ status, statusText, headers, body }) => {
        let json;
        try {
            json = JSON.parse(body);
        } catch (e) {
            // not json, no big deal
        }
        if (status < 200 || status >= 300) {
            return Promise.reject(
                new HttpError(
                    (json && json.message) || statusText,
                    status,
                    json
                )
            );
        }

        return Promise.resolve({ status, headers, body, json })
    })
};


const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;


        return httpClient(url).then(({ headers, json }) => (
            {
                data: json,
                total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
            }
        ));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    getSchema: (resource) => {

        const url = `${apiUrl}/${resource}/schema`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    update: (resource, params) => {

        if (params.data.announcement_banner != null) {
            return httpClientAnnouncementBannerFiles(`${apiUrl}/${resource}/${params.id}`, params, 'PUT')
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (resource === 'hotel-brands') {
            console.log('---------- update ----------')
            return httpClientHotelLogoFiles(`${apiUrl}/${resource}/${params.id}`, params, 'PUT')
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.icon != null) {
            return httpClientSubCategoryUpdateFile(`${apiUrl}/${resource}/${params.id}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        } else if (resource === 'categories') {
            return httpClientCategoryUpdateFile(`${apiUrl}/${resource}/${params.id}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        } else if (resource === 'users') {
            console.log('---------- update ----------')
            return httpClientPartnerLogoFiles(`${apiUrl}/${resource}/${params.id}`, params, 'PUT')
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id }, 
            }))
        }
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        if (params.data.file != null) {
            return httpClientFile(`${apiUrl}/${resource}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        } else if (resource === 'hotel-brands') {
            console.log('---------- create ----------')
            return httpClientHotelLogoFiles(`${apiUrl}/${resource}`, params, 'POST')
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else if (params.data.icon != null) {
            return httpClientSubCategoryFile(`${apiUrl}/${resource}`, params)
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })

        } else if (resource === 'users') {
            console.log('---------- create ----------')
            return httpClientPartnerLogoFiles(`${apiUrl}/${resource}`, params, 'POST')
            .then(({ json }) => {
                return {
                    data: { ...params.data, id: json.id },
                }
            })
        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        }
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    }
};

export default dataProvider;
