import CustomTableCreate from './CustomTableCreate';

class ProductTagCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'immutable') {
            return true
        }
        return false
    }

}

export default ProductTagCreate;
