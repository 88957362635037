import CustomTableShow from './CustomTableShow';

class TagShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'immutable') {
            return true
        }
        return false
    }
}

export default TagShow;
