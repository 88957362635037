import { useRecordContext } from "react-admin";

export default function ConditionalField({ condition, ...props }) {
    const record = useRecordContext(props);

    if (!record) {
        return <></>;
    }

    if (!condition(record)) {
        return <></>;
    }

    return props.children;
}
