import React from 'react';

import CustomTableList from './CustomTableList';
import ElipsizeTextField from '../fields/ElipsizeTextField'
import { ImageField } from 'react-admin';

class CategoryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'slug') {
            return true
        } else if (fieldName === 'description') {
            fieldList.push(<ElipsizeTextField source={fieldName} field={fieldName} maxLength={150}/>)
            return true
        } else if (fieldName === 'small_icon') {
            fieldList.push(<ImageField className="thumbNailView" label="Small icon" source="small_icon.url" title="small_icon.title" />)
            return true
        }
        return false
    }
}

export default CategoryList;
