import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    BooleanInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';


class HotelContactCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'hotel_brand') {
            fieldList.push(
                <ReferenceInput source="hotel_brand" label="Hotel brand" reference="hotel-brands" allowEmpty>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            )
            return true
        } else if (fieldName === 'country') {
            fieldList.push(
                <ReferenceInput source="country" label="Country" reference="countries" allowEmpty>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            )
            return true
        } else if (fieldName === 'district') {
            fieldList.push(
                <TextInput source={fieldName} label='Additional information' />
            )
            return true
        } else if (fieldName === 'recap_email') {
            fieldList.push(
                <TextInput source={fieldName} label='Email' />
            )
            return true
        } else if (fieldName === 'isUpComing') {
            fieldList.push(
                <BooleanInput source={fieldName} label={'Pipeline'} />
            )
            return true
        }

        return false
    }

}

export default HotelContactCreate;
