import React from 'react';
import slugify from 'slugify'
import {
    TextInput,
    ImageInput,
    ImageField,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';

class SubCategoryEdit extends CustomTableEdit {

    state = {  
        ...super.state,
    }

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'hash') {
            return true
        } else if (fieldName === 'slug') {
            fieldList.push(<TextInput
                label="Slug"
                source={fieldName}
                onInput = {(e) =>{
                    e.target.value = slugify(e.target.value).toLowerCase()
                }}/>)
            return true
        } else if (fieldName === 'description') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput source={fieldName} label={fieldLabel} multiline/>)
            return true
        } else if (fieldName === 'icon') {
            fieldList.push(<ImageInput source={fieldName} label="Icon" accept="image/*">
                <ImageField source="url" title="title" />
            </ImageInput>)
            return true
        } else if (fieldName === 'background') {
            fieldList.push(<ImageInput source={fieldName} label="Background" accept="image/*">
                <ImageField source="url" title="title" />
            </ImageInput>)
            return true
        } else if (fieldName === 'small_icon') {
            fieldList.push(<ImageInput source={fieldName} label="Small icon (accept .svg)" accept="image/svg+xml">
                <ImageField source="url" title="title" />
            </ImageInput>)
            return true
        }
        return false
    }

}

export default SubCategoryEdit;
