import React from 'react';
import {
    DateField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';


const useStyles = makeStyles({
    ok: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'orange' },
});

const ActiveField = props => {
    const classes = useStyles();

    const dateEnd = new Date(props.record[props.source]);

    const now = new Date();
    const inAMonth = new Date();
    inAMonth.setDate(inAMonth.getDate() + 30);

    console.log(`date: ${dateEnd}`)
    console.log(`ok: ${dateEnd > inAMonth}`)
    console.log(`warning1: ${dateEnd > now}`)
    console.log(`warning2: ${dateEnd <= inAMonth}`)
    console.log(`error: ${dateEnd <= now}`)
    return (
        <DateField
            className={classnames({
                [classes.ok]: dateEnd > inAMonth,
                [classes.warning]: dateEnd > now && dateEnd <= inAMonth,
                [classes.error]: dateEnd <= now,
            })}
            {...props}
        />
    );
};

export default ActiveField;
