import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    DateInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import utils from '../utils/utils';
import { COUNTRY_TITLE } from '../utils/constants';


class ExhibitorCreate extends CustomTableCreate {

    ignoredFields = [
        'contact',
        'contact_text',
        'wechat_qr_code',
        'wechat',
        'website_url',
        'category',
        'subcategory',
        'tags',
        'description',
        'logo',
        'carousel',
        'catalogs',
        'images_videos',
        'videos',
        'products',
        'product_ids',
        'thumbnails',
        'background',
        'history',
        'lifestyle',
        'lifestyles',
        'history_media',
        'video_thumbmnail_links',
        'facebook_link',
        'instagram_link',
        'linkedin_link',
        'tiktok_link',
        'kakao_link',
        'youtube_link',
        'red_link',
        'weibo_link',
        'published',
        'lastEmailSent',
        'countriesAvailable',
        'subscription_type'
    ]

    customHandled(fields, fieldName, fieldList) {
        if (this.ignoredFields.includes(fieldName)) {
            return true
        } else if (fieldName === 'name') {
            fieldList.push(<TextInput source={fieldName} label="Brand name"/>)
            return true
        } else if (fieldName === 'exhibitor') {
            const plural = utils.getPlural(fields[fieldName].ref)
            fieldList.push(<ReferenceInput label="Account name" source={fieldName} reference={plural} allowEmpty>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>)
                        return true
        } else if (fieldName === 'hyatt_tags') {
            // fieldList.push(<ReferenceArrayInput source="hyatt_tags" label="Hyatt Tags" reference="hyatt-tags" allowEmpty>
            //     <SelectArrayInput optionText="name" />
            // </ReferenceArrayInput>);
            return true;
        } else if (fieldName === 'countries') {
            fieldList.push(
                <ReferenceArrayInput source="countries" label={COUNTRY_TITLE} reference="countries" allowEmpty>
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
            )
            return true
        } else if (fieldName === 'subscription_end') {
            const inOneYear = new Date();
            inOneYear.setFullYear(inOneYear.getFullYear() + 1);

            fieldList.push(
                <DateInput source={fieldName} defaultValue={inOneYear} />
            )

            return true
        }

        return false
    }

}

export default ExhibitorCreate;
