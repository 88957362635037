import React from 'react';
import {
    ImageField,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class SubCategoryList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'hash') {
            return true
        } else if (fieldName === 'icon') {
            fieldList.push(<ImageField className="thumbNailView" label="Icon" source="icon.url" title="icon.title" />)
            return true
        } else if (fieldName === 'background') {
            fieldList.push(<ImageField className="thumbNailView" label="Background" source="background.url" title="background.title" />)
            return true
        } else if (fieldName === 'small_icon') {
            fieldList.push(<ImageField className="thumbNailView" label="Small icon" source="small_icon.url" title="small_icon.title" />)
            return true
        }
        return false
    }
}

export default SubCategoryList;
