import React from 'react';
import {
    TextInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import utils from '../utils/utils'
import { PRODUCT_TITLE } from '../utils/constants';

class ProductEdit extends CustomTableEdit {
    state = {  
        ...super.state,
        popupDelete: true
    }

    ShowTitle = () => utils.getPlural(PRODUCT_TITLE);

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'exhibitor' ||
            fieldName === 'category' ||
            fieldName === 'subcategory' ||
            fieldName === 'tags' ||
            fieldName === 'product' ||
            fieldName === 'disabled' ||
            fieldName === 'subscription_end' ||
            fieldName === 'countries'
            ) {
            return true
        } else if (fieldName === 'name') {
            fieldList.push(<TextInput source={fieldName} label="Product name"/>)
            return true
        } 
        return false
    }

}

export default ProductEdit;
