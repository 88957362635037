import React from 'react';
import {
    Show,
    SimpleShowLayout,
    ImageField,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class HomepageList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        return false;
    }

    PostShow(props){
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout>
                <ImageField source="announcement_banner.url" field="announcement_banner.url" label="Banner" />
            </SimpleShowLayout>
        </Show>
    }

}

export default HomepageList;
