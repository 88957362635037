import utils from '../utils/utils'
import { COUNTRY_TITLE } from '../utils/constants';
import CustomTableShow from './CustomTableShow';

class CountryShow extends CustomTableShow {
    ShowTitle = () => utils.getPlural(COUNTRY_TITLE);

    customHandled() {
        return false;
    }
}

export default CountryShow;
