import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    DateInput,
    BooleanInput,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForRessource';
import dateUtils from '../utils/dateUtils';
import utils from '../utils/utils';

const redirect = (basePath, id, data) => `${basePath}`;


class CustomTableCreate extends React.Component {
    state = { data: null }

    customHandled(fields, fieldName, fieldList) {
        return false
    }

    generateFromFields(fields) {
        let that = this

        var fieldList = []
        for (const fieldName in fields) {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            if (!this.customHandled(fields, fieldName, fieldList)) {

                if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined) {
                    fieldList.push(<SelectInput label={fieldLabel} source={fieldName} choices={this.transformInChoices(fields[fieldName].enum)} />)
                } else if (fields[fieldName].type === 'String') {
                    fieldList.push(<TextInput label={fieldLabel} source={fieldName}/>)
                } else if (fields[fieldName].type === 'Number') {
                    fieldList.push(<NumberInput label={fieldLabel} source={fieldName}/>)
                } else if (fields[fieldName].type === 'Date') {
                    // fieldList.push(<DateInput label={fieldName} source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                    fieldList.push(<DateInput label={fieldLabel} source={fieldName} parse={dateUtils.dateParser} />)
                } else if (fields[fieldName].type === 'Boolean') {
                    fieldList.push(<BooleanInput label={fieldLabel} source={fieldName} />)
                } else if (fields[fieldName].type === 'ObjectId') {
                    const plural = utils.getPlural(fields[fieldName].ref)
                    fieldList.push(<ReferenceInput label={fieldLabel} source={fieldName} reference={plural} allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>)
                } else if (Array.isArray(fields[fieldName].type)) {

                    console.log(fields[fieldName].type)

                    var subObject = that.generateFromFields(fields[fieldName].type[0])
                    
                    fieldList.push(<ArrayInput source={fieldName}>
                        <SimpleFormIterator>
                            {subObject}
                        </SimpleFormIterator>
                    </ArrayInput>)

                    // fieldList.push(<ReferenceArrayInput label={fieldName} source={fieldName} reference={fieldName} allowEmpty>
                    //         {/* <ChipField source="name" /> */}
                    //         <SelectArrayInput optionText="name" />
                    // </ReferenceArrayInput>)
                }
            }
        };

        return fieldList
    }

    componentDidMount() {
        let that = this
        return getFieldsForRessource(this.props.resource).then((fields) => {

            var fieldList = that.generateFromFields(fields)
    
            this.setState({
                data: <Create {...this.props}>
                <SimpleForm redirect={redirect}>
                    {fieldList}
                </SimpleForm>
            </Create>
            });
        });
    }

    render() {
    
        if (!this.state.data) {
          return <div />
        }

        return this.state.data;
    }

    transformInChoices(base) {
        let returnObject = []
        for (const obj in base) {
            returnObject.push({
                "id": base[obj],
                "name": base[obj]
            })
        }
        return returnObject;
    }

    transformInChoicesWithout(base, avoidList) {
        let returnObject = []
        for (const obj in base) {
            if (avoidList.indexOf(base[obj]) === -1) {
                returnObject.push({
                    "id": base[obj],
                    "name": base[obj]
                })
            }
        }
        return returnObject;
    }
}

export default CustomTableCreate;
