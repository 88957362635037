import React from 'react';

const ImageFromAPIField = ({ record, field, source, folder, height }) => {

    if (!record) {
        return <div></div>;
    }

    let obj = record;
    if (source) {
        obj = record[source];
        if (source.split('[').length > 1) {
            obj = record[source.split('[')[0]][parseInt(source.split('[')[1])][source.split('.')[1]];
        }
    }

    if (obj && obj[field]) {
        const str = obj[field];
        if (str) {
            return (
                <div>
                    <img src={`${process.env.REACT_APP_API_URL}/storage/${folder}/${str}`} alt={str} height={height} />
                </div>
            );
        }
    }
    return <a href={record[field]} >{record.title}</a>;

};

export default ImageFromAPIField;