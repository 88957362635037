import React from 'react';
import {
    ImageField,
    FileField,
    ReferenceField,
    TextField,
    ArrayField,
    SingleFieldList,
    ReferenceArrayField,
    ChipField,
    DateField,
    BooleanField,
} from 'react-admin'
import CustomTableShow from './CustomTableShow';
import FrontUrlField from '../fields/FrontUrlField'
import ImageOrVideoField from '../fields/ImageOrVideoField'
import Statistics from '../fields/Statistics'
import utils from '../utils/utils';
import { COUNTRY_TITLE, EXHIBITOR_TITLE } from '../utils/constants';

class ExhibitorShow extends CustomTableShow {
    ShowTitle = () => utils.getPlural(EXHIBITOR_TITLE);

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === "published" ||
            fieldName === 'lastEmailSent' ||
            fieldName === 'product_ids' ||
            fieldName === 'lifestyle' ||
            fieldName === 'countriesAvailable' ||
            fieldName === 'subscription_type'
            ) {
            return true
        } else if (fieldName === "wechat_qr_code") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ImageField label="Wechat QR code" source="wechat_qr_code.url" title="wechat_qr_code.title" />)
            // fieldList.push(<AuthImageField sourceDesktop="wechat_qr_code" />)
            return true
        } else if (fieldName === "contact") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<FileField label="Contact" source="contact.url" title="contact.title" target="_blank"/>)
            return true
        } else if (fieldName === 'logo') {
            fieldList.push(<ImageField className="thumbNailView" label="Logo" source="logo.url" title="logo.title" />)
            return true;
        } else if (fieldName === 'history') {
            fieldList.push(<TextField source={fieldName} label="About"/>)
            return true
        } else if (fieldName === 'subscription_end') {
            fieldList.push(<DateField label="Subscription ends" source={fieldName} />)
            return true;
        } else if (fieldName === 'tags') {
            fieldList.push(<ReferenceArrayField source="tags" reference="tags">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>)
            return true
        } else if (fieldName === 'hyatt_tags') {
            fieldList.push(<ReferenceArrayField source="hyatt_tags" label="Announcement Tags" reference="hyatt-tags">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>)
            return true
        } else if (fieldName === 'countries') {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ReferenceArrayField source="countries" reference="countries" label={COUNTRY_TITLE}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>)
            return true
        }  else if (fieldName === "catalogs") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<FileField source="catalogs" src="url" title="title" target="_blank"/>)
            return true
        } else if (fieldName === "carousel") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ArrayField source={fieldName}>
                <SingleFieldList linkType={false}>
                    <ImageOrVideoField source="url" field="url" title="carousel" />
                </SingleFieldList>
            </ArrayField>)
            return true
        } else if (fieldName === "images_videos") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ArrayField source={fieldName}>
                <SingleFieldList linkType={false}>
                    <ImageOrVideoField source="url" field="url" title="title" />
                </SingleFieldList>
            </ArrayField>)
            return true
        } else if (fieldName === "thumbnails") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ArrayField source={fieldName}>
                <SingleFieldList linkType={false}>
                    <ImageOrVideoField source="url" field="url" title="title" />
                </SingleFieldList>
            </ArrayField>)
            return true
        } else if (fieldName === "category") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ReferenceField label="Category" source="category.id" reference="categories" link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true
        } else if (fieldName === "subcategory") {
            console.log(`I handle ${fieldName}`)
            fieldList.push(<ReferenceField label="Subcategory" source="subcategory" reference="subcategories" link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true
        } else if (fieldName === "website_url") {
            fieldList.push(<FrontUrlField label="HDE link" source="name" target="_blank"/>)
        } else if (fieldName === 'disabled') {
            fieldList.push(<BooleanField source={fieldName} />)
            fieldList.push(<Statistics source='Statistics' />)
            return true
        } else if (fieldName === 'exhibitor') {
            console.log(`I handle ${fieldName}`)
            const plural = utils.getPlural(fields[fieldName].ref)
            
            fieldList.push(<ReferenceField label="Exhibitor" source="exhibitor.id" reference={plural} link="show">
                <TextField source="name" />
            </ReferenceField>)
            return true
        }
        return false
    }
}

export default ExhibitorShow;
