import CustomTableEdit from './CustomTableEdit';

class ProductTagEdit extends CustomTableEdit {

    state = {  
        ...super.state,
        popupDelete: true
    }

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'immutable') {
            return true
        }
        return false
    }

}

export default ProductTagEdit;
